import React from 'react'
import './Footer.scss'

import Avatar from '../../images/Avatar.jpg'
import Button from '../Button/Button'

function Footer() {
  return (
    <footer className='project-footer padding'>
      <div className="inner-footer">
        <div className="left-container">
          <div className="avatar">
            <img src={Avatar} alt="" />
          </div>
        </div>
        <div className="right-container">
          <h2>Let's Work Together</h2>
          <p>I am a front-end developer currently based in the Twin Cities, send me a message and lets get in touch!</p>
          <Button path='mailto' text='Contact Me' />
        </div>
      </div>
    </footer>
  )
}

export default Footer