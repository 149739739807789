import React from "react";
import "./chat.scss";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

function Chat() {
  return (
    <>
      <Navbar />
      <div className="chat-app padding">
        <div className="left-container">
          <h1 className="page-heading">
            <span>Chat Application</span>
            <span className="dot">.</span>
          </h1>
          <section>
            <p>
              The classic chat application with a few twists. This project was
              built as a playground for features that I would eventually add to
              a similar chat component used within an application at work.
            </p>
            <p>
              Utilizing Ionic and Angular, my initial plan was to build an
              iMessage clone and add any unique features after the design was
              complete. This was made very simple using Ionic components such as
              ion-toolbar, ion-grid, and ion-list. This wasn't the first time
              that I had used Ionic components but I was suprised at how perfect
              the stock components seemed to be made for exaclty what I was
              doing. I have included mock data within the project to initialize
              a few message threads as well as a simple random-response
              generator. Feel free to message some of the contacts and see if
              they respond!
            </p>
          </section>

          <section>
            <h2>Why?...</h2>
            <p>
              The need for a serious redesign was in order for an SMS messaging
              view in a mobile application at my current company. Previously
              messages were displaying individually and not in a thread view.
              This made digging for past messages difficult and slow. The need
              for this updated view was paired with a few other niche design
              features that are unique to the application:
              <ul>
                <li>Display the cost of the current message</li>
                <li>Indicate if the recipient is a verified user</li>
                <li>Include image support</li>
              </ul>
            </p>
            <h3>Cost per Message</h3>
            <p>
              It was a requirement that users interacting with this application
              would be able to see how much their message is going to cost to
              send. These messages are sent as SMS in our application so there
              is a cost per 160 characters. This was accomplished by leveraging
              the ionChange event and simply using Javascript’s Math.ceil() on
              the characters typed. The result was then multiplied by the rate,
              set by admin users, from the backend. Price indication can be seen
              clearly above the send icon.
              <br />
              <br />
              The unique feature here is that the pricing model charges double
              for sending messages to an unverified user. This is easily
              accomplished by checking a user’s status, and based on their phone
              number, adjusting the rate accordingly.
            </p>
            <h3>Verified User Indication</h3>
            <p>
              Not a huge feature, but it seemed a necessary design choice to
              indicate whether or not the recipient is a verified user.
              Otherwise, users would wonder why they are being charged double
              for every message.
              <br />
              <br />
              This was done by changing the thread header text to the verified
              user’s name instead of their phone number. A colored user icon was
              added as well to draw attention to the change.
            </p>
            <h3>Image Support</h3>
            <p>
              Including images within the thread view proved to be more
              difficult than I imagined. The images tended to load after the
              text of the messages appeared on page. This caused the entire
              message thread to grow and thus the user’s scroll position was no
              longer the latest message in the thread. This was solved by making
              a custom image directive that would not reveal the view and
              scrollToBottom until all images were loaded.
              <br />
              <br />
              Users also wanted a gallery view where they could not only see the
              images currently displayed within the message thread but all
              messages sent between them and the recipient. A separate route for
              getting all images between users was the solution. As expected,
              displaying hundreds of images was proving to be slow and choppy so
              the ion-infinite-scroll came in handy and was easy to setup.
            </p>
          </section>

          <section>
            <h2>Conclusion and Future Plans</h2>
            <p>
              This was such a fun project to build and I really enjoyed learning
              more about Ionic. The required extra features provided an
              interesting experience on top of the generic chat application.
              <br />
              <br />
              In the future, I would love to include a simple backend that would
              allow messages to persist as people visit this page and talk to
              certain users. I have also considered adding myself as a contact
              and allowing people to text me right from the browser. This would
              be implemented using Twilio.
            </p>
          </section>
        </div>
        <div className="right-container">
          <figure>
            <iframe src="https://chat.beauherrondev.com"></iframe>
          </figure>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Chat;
